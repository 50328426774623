import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useAppStore, ThemeType } from '../../app.store'

import { Tabs } from '../../components/Tabs'
import LoginForm from './compositions/login'
import SignupForm from './compositions/signup'

import { SysprefWidget } from '../../components/SysprefWidget'
import { Loader } from '../../components/Loader'

import styles from './styles.module.css'

import LogoLight from './../../../public/assets/images/logo-big-light.svg'
import LogoDark from './../../../public/assets/images/logo-big-dark.svg'

interface LoginProps {}

const Login = (props: LoginProps) => {
  const { t } = useTranslation()
  const { theme } = useAppStore()

  const [activeTab, setActiveTab] = useState<string>('1')
  const [shouldRenderLoad, setShouldRenderLoader] = useState<boolean>(false)

  const getLogo = () => {
    let Logo = LogoLight
    if (theme == ThemeType.Dark) Logo = LogoDark

    return <Logo />
  }

  const toggleLoader = (val: boolean) => {
    setShouldRenderLoader(val)
  }

  const tabItems = [
    {
      key: '1',
      label: t('homeView.login'),
    },
    {
      key: '2',
      label: t('homeView.signUp'),
    },
  ]

  const getTabContent = () => {
    // LOGIN CONTENT
    if (activeTab == tabItems[0].key) {
      return <LoginForm theme={theme} toggleLoader={toggleLoader} />
    } else {
      // SIGN UP CONTENT
      // return (
      //   <SignupForm theme={theme} error={error} onSignup={onSubmitSignup} />
      // )
      null
    }
  }

  return (
    <div className={styles.viewContainer}>
      {shouldRenderLoad && <Loader theme={theme} />}

      <div className={styles.preferencesContainer}>
        <SysprefWidget />
      </div>

      <div className={styles.logoContainer}>{getLogo()}</div>

      {/* FORM */}
      <div className={styles.formContainer}>
        <div className={styles.tabContainer}>
          <Tabs
            items={tabItems}
            theme={theme}
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          />
        </div>
        <div
          className={classnames([styles.separator, styles[`${theme}`]])}
        ></div>
        {getTabContent()}
      </div>

      <div className={classnames([styles.footerContainer, styles[`${theme}`]])}>
        {t('common.footer')}
      </div>
    </div>
  )
}

export default Login
