import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Button } from '../../components/Button'
import { InputText, InputType } from '../../components/InputText'
import { SysprefWidget } from '../../components/SysprefWidget'

import { useAppStore, ThemeType } from '../../app.store'

import styles from './styles.module.css'

import LogoLight from './../../../public/assets/images/logo-big-light.svg'
import LogoDark from './../../../public/assets/images/logo-big-dark.svg'
import IconLanguage from './../../../public/assets/icons/global.svg'
import IconThemeLight from './../../../public/assets/icons/sun.svg'
import IconThemeDark from './../../../public/assets/icons/moon.svg'
import IconEmailTick from './../../../public/assets/images/email-tick.svg'
import IconTick from './../../../public/assets/images/tick.svg'

interface SignupPropsType {}

const Signup = (props: SignupPropsType) => {
  const { t } = useTranslation()
  const { theme } = useAppStore()
  const navigate = useNavigate()

  const [recoveryStep, setRecoveryStep] = useState<number>(1)
  const [verificationCode, setVerificationCode] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>()

  const getLogo = () => {
    let Logo = LogoLight
    if (theme == ThemeType.Dark) Logo = LogoDark

    return <Logo />
  }

  const submitVerify = () => {
    console.log(`submitting verify for ${verificationCode}`)
    setRecoveryStep(2)
  }

  const submitResendEmail = () => {
    console.log(`submitting resend e-mail to ${email}`)
  }

  const gotoLogin = () => {
    navigate('/')
  }

  const getStepContent = () => {
    if (recoveryStep === 1) {
      return (
        <div className={classnames(styles.recovery1, styles[`${theme}`])}>
          <div className={classnames(styles.imageContainer)}>
            <IconEmailTick />
          </div>

          <div
            className={classnames([styles.textContainer, styles[`${theme}`]])}
          >
            {t('signupView.step1.instructions')} <b>xxx</b>
            <div
              className={classnames([
                styles.smallTextContainer,
                styles[`${theme}`],
              ])}
            >
              {t('signupView.step1.instructionsDetails')}
            </div>
          </div>

          <div className={classnames(styles.formContainer)}>
            <InputText
              onChange={(val) => setVerificationCode(val)}
              theme={theme}
              placeholder={t('signupView.step1.validationCode')}
              isRequired={true}
              tabIndex={1}
              onPressEnter={submitVerify}
            />
            <div className={classnames(styles.buttonContainer)}>
              <Button
                label={t('signupView.step1.btnVerify')}
                onClick={submitVerify}
                theme={theme}
              />
            </div>
          </div>
          <div
            className={classnames(styles.resendContainer)}
            onClick={submitResendEmail}
          >
            {t('signupView.step1.resendEmail')}
          </div>
        </div>
      )
    } else if (recoveryStep === 2) {
      return (
        <div className={classnames(styles.recovery2, styles[`${theme}`])}>
          <div className={classnames(styles.imageContainer)}>
            <IconTick />
          </div>

          <div
            className={classnames([styles.textContainer, styles[`${theme}`]])}
          >
            {t('signupView.step2.instructions')} <b>{email}</b>
          </div>

          <div className={classnames(styles.buttonContainer)}>
            <Button
              label={t('signupView.step2.btnProceed')}
              onClick={gotoLogin}
              theme={theme}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div className={styles.viewContainer}>
      <div className={styles.preferencesContainer}>
        <SysprefWidget />
      </div>

      <div className={styles.logoContainer}>{getLogo()}</div>

      <div className={styles.stepsContainer}>{getStepContent()}</div>

      <div className={classnames([styles.footerContainer, styles[`${theme}`]])}>
        Optimal - Copyright ©2020 Produced by Optimal
      </div>
    </div>
  )
}

export default Signup
