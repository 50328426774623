import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Button } from '../../../../components/Button'

import IconTick from './../../../../../public/assets/images/tick.svg'

import styles from './styles.module.css'

interface Step3PropsType {
  theme: string
  onClick: () => void
}

export const Step3 = (props: Step3PropsType) => {
  const { t } = useTranslation()

  return (
    <div className={classnames(styles.recovery4, styles[`${props.theme}`])}>
      <div className={classnames(styles.imageContainer)}>
        <IconTick />
      </div>

      <div
        className={classnames([styles.textContainer, styles[`${props.theme}`]])}
      >
        {t('recoveryView.step4.instructions')}
      </div>

      <div className={classnames([styles.buttonContainer])}>
        <Button
          label={t('recoveryView.step4.btnProceed')}
          onClick={props.onClick}
          theme={props.theme}
        />
      </div>
    </div>
  )
}
