import React, { useState } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export enum StatesType {
    ENABLED = 'enabled',
    DISABLED = 'disabled'
}

interface ButtonPropsType {
    label: string,
    onClick: () => void,
    state?: StatesType,
    theme?: string
}

export const Button = (props: ButtonPropsType) => {
    const onClick = (): void => {
        props.onClick()    
    }

    return (
        <div className={ classnames([styles.buttonContainer, styles[`${props.theme}`]])} onClick={ onClick }>
            { props.label }
        </div>
    )
}