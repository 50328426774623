import React, { useState, useEffect, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import classnames from 'classnames'

import { useAppStore, ThemeType } from './app.store'

import ProtectedRoute from './components/ProtectedRoute'
import Soon from './views/Soon'
import Login from './views/Login'
import Recovery from './views/Recovery'
import Signup from './views/Signup'
import Dashboard from './views/Dashboard'

import styles from './app.module.css'
import themes from './components/themes/themes.module.css'

interface AppProps {}

const App = (props: AppProps) => {
  const [data, setData] = useState('App')
  const { theme, setTheme } = useAppStore()

  useEffect(() => {
    // Setting Application Theme
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      if (theme != 'dark') setTheme(ThemeType.Dark)
    } else {
      if (theme != 'light') setTheme(ThemeType.Light)
    }
  }, [])

  return (
    <Suspense fallback={'...loading'}>
      <div className={classnames([styles.appContainer, styles[`${theme}`]])}>
        <div
          className={classnames([
            styles.backgroundGradient,
            styles[`${theme}`],
            themes.theme,
            themes[`${theme}`],
          ])}
        ></div>
        <div className={styles.contentContainer}>
          <Routes>
            <Route path="/" element={<Soon />} />
            <Route path="/login" element={<Login />} />
            <Route path="/recovery" element={<Recovery />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute
                  requiredRoles={['god', 'user']}
                  fallbackRoute={'/'}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </div>
      </div>
    </Suspense>
  )
}

export default App
