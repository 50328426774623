import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useAppStore, ThemeType } from '../../app.store'

import { SysprefWidget } from '../../components/SysprefWidget'

import styles from './styles.module.css'

import LogoLight from './../../../public/assets/images/logo-big-light.svg'
import LogoDark from './../../../public/assets/images/logo-big-dark.svg'

interface LoginProps {}

const Soon = (props: LoginProps) => {
  const { t } = useTranslation()
  const { theme } = useAppStore()

  const getLogo = () => {
    let Logo = LogoLight
    if (theme == ThemeType.Dark) Logo = LogoDark

    return <Logo />
  }

  return (
    <div className={styles.viewContainer}>
      <div className={styles.preferencesContainer}>
        <SysprefWidget />
      </div>

      <div className={styles.logoContainer}>{getLogo()}</div>

      <div className={classnames([styles.footerContainer, styles[`${theme}`]])}>
        {t('common.footer')}
      </div>
    </div>
  )
}

export default Soon
