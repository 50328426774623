import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface LoaderPropsType {
    theme? : string
}

export const Loader = (props: LoaderPropsType) => {
    return (
        <div className={ classnames([styles.loaderContainer, styles[`${props.theme}`]]) }>
            <div className={ classnames([styles.loaderBox, styles[`${props.theme}`]])}>
                <div className={ classnames([styles.loader, styles[`${props.theme}`]])} >
                    </div>
            </div>
        </div>
    )
}