import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { Button } from '../../../../components/Button'
import { InputText } from '../../../../components/InputText'

import { ENUM_FEEDBACK_TYPE } from './../../../../components/FormFeedback'
import type { FeedbackObjType } from './../../../../components/FormFeedback'

import styles from './styles.module.css'

import IconEmailNormal from './../../../../../public/assets/images/email-normal.svg'

interface Step1PropsType {
  theme: string
  onSubmit: (email: string | undefined) => void
  error?: FeedbackObjType
}

export const Step1 = (props: Step1PropsType) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState<string | undefined>()

  const submit = () => {
    props.onSubmit(email)
  }

  const getValidation = () => {
    if (props.error) {
      return {
        type: props.error.type,
        message: t(`recoveryView.step1.${props.error.message}`),
      }
    } else {
      return undefined
    }
  }

  return (
    <div className={classnames(styles.recovery1, styles[`${props.theme}`])}>
      <div className={classnames(styles.imageContainer)}>
        <IconEmailNormal />
      </div>

      <div
        className={classnames([styles.textContainer, styles[`${props.theme}`]])}
      >
        {t('recoveryView.step1.instructions')}
      </div>

      <div className={classnames(styles.formContainer)}>
        <InputText
          onChange={(val) => setEmail(val)}
          theme={props.theme}
          placeholder={t('recoveryView.step1.email')}
          isRequired={true}
          tabIndex={1}
          onPressEnter={submit}
          validation={getValidation()}
        />
        <div className={classnames(styles.buttonContainer)}>
          <Button
            label={t('recoveryView.step1.btnRecover')}
            onClick={submit}
            theme={props.theme}
          />
        </div>
      </div>
    </div>
  )
}
