import './i18n'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './app'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.NODE_ENV === 'development') 
    new EventSource('/esbuild').addEventListener('change', () => location.reload())

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
