export default {
  "viewContainer": "_viewContainer_s6j9y_4",
  "preferencesContainer": "_preferencesContainer_s6j9y_10",
  "buttonContainer": "_buttonContainer_s6j9y_20",
  "svgIcon": "_svgIcon_s6j9y_28",
  "dark": "_dark_s6j9y_33",
  "light": "_light_s6j9y_37",
  "logoContainer": "_logoContainer_s6j9y_48",
  "footerContainer": "_footerContainer_s6j9y_57",
  "stepsContainer": "_stepsContainer_s6j9y_75",
  "recovery1": "_recovery1_s6j9y_81",
  "imageContainer": "_imageContainer_s6j9y_87",
  "textContainer": "_textContainer_s6j9y_93",
  "smallTextContainer": "_smallTextContainer_s6j9y_106",
  "formContainer": "_formContainer_s6j9y_119",
  "resendContainer": "_resendContainer_s6j9y_132",
  "recovery2": "_recovery2_s6j9y_143"
};import "ni:sha-256;Yem-7stHWqInrXnFSMg0QSAaMj9fheVnDqbDh1kcwH4";