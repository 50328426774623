export default {
  "viewContainer": "_viewContainer_1nyfq_4",
  "preferencesContainer": "_preferencesContainer_1nyfq_10",
  "logoContainer": "_logoContainer_1nyfq_21",
  "formContainer": "_formContainer_1nyfq_30",
  "tabContainer": "_tabContainer_1nyfq_37",
  "separator": "_separator_1nyfq_41",
  "dark": "_dark_1nyfq_45",
  "light": "_light_1nyfq_48",
  "footerContainer": "_footerContainer_1nyfq_54"
};import "ni:sha-256;YNnyTiUxvlcC45VHFE-jHXwQvLiWPiT6KpmTCPH8pYE";