import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'

import { useForgotPassword } from './../../API/user/forgotPassword'
import { useResetPassword } from './../../API/user/resetPassword'

import { Step1 } from './compositions/Step1'
import { Step2 } from './compositions/Step2'
import { Step3 } from './compositions/Step3'

import { Loader } from '../../components/Loader'
import { SysprefWidget } from '../../components/SysprefWidget'
import { ENUM_FEEDBACK_TYPE } from './../../components/FormFeedback'

import { useAppStore, ThemeType } from '../../app.store'

import styles from './styles.module.css'

import LogoLight from './../../../public/assets/images/logo-big-light.svg'
import LogoDark from './../../../public/assets/images/logo-big-dark.svg'

interface RecoveryPropsType {}

const Recovery = (props: RecoveryPropsType) => {
  console.log('rendered')

  const { theme } = useAppStore()
  const navigate = useNavigate()

  const {
    loading: forgotPasswordLoading,
    error: forgotPasswordError,
    success: forgotPasswordSuccess,
    forgotPassword,
  } = useForgotPassword()

  const {
    loading: resetPasswordLoading,
    error: resetPasswordError,
    success: resetPasswordSuccess,
    resetPassword,
  } = useResetPassword()

  const [email, setEmail] = useState<string | undefined>()
  const [recoveryStep, setRecoveryStep] = useState<number>(1)
  const [step1Error, setStep1Error] = useState<string | undefined>()
  const [step2Error, setStep2Error] = useState<string | undefined>()

  // API - Step 1
  useEffect(() => {
    if (forgotPasswordSuccess) {
      setRecoveryStep(2)
      return
    }
    if (forgotPasswordError) {
      setStep1Error(forgotPasswordError)
      return
    }
  }, [forgotPasswordSuccess, forgotPasswordError])

  // API - Step 2
  useEffect(() => {
    if (resetPasswordSuccess) {
      setRecoveryStep(3)
      return
    }

    if (resetPasswordError) {
      setStep2Error(resetPasswordError)
      return
    }
  }, [resetPasswordSuccess, resetPasswordError])

  const getLogo = () => {
    let Logo = LogoLight
    if (theme == ThemeType.Dark) Logo = LogoDark
    return <Logo />
  }

  const shouldRenderLoader = () => {
    if (forgotPasswordLoading || resetPasswordLoading) {
      return <Loader theme={theme} />
    }
    return null
  }

  const submitEmail = (email: string | undefined) => {
    if (!email) {
      setStep1Error('invalidEmail')
      return
    }

    setStep1Error(undefined)
    setEmail(email)
    forgotPassword(email)
  }

  const submitNewPassword = (
    token: string | undefined,
    password: string | undefined,
    confirmPassword: string | undefined
  ) => {
    if (!password || !confirmPassword) {
      console.log('setting passwordsRequired error')
      setStep2Error('passwordsRequired')
      return
    }

    if (password !== confirmPassword) {
      console.log('setting unmatchingPasswords error')
      setStep2Error('unmatchingPasswords')
      return
    }

    if (!token) {
      console.log('setting noToken error')
      setStep2Error('noToken')
      return
    }

    setStep2Error(undefined)
    resetPassword(token, password)
  }

  const resendEmail = () => {
    console.log(`submitting resend email: ${email}`)
  }

  const gotoLogin = () => {
    navigate('/')
  }

  const getStepContent = () => {
    if (recoveryStep === 1) {
      return (
        <Step1
          theme={theme}
          onSubmit={submitEmail}
          error={
            step1Error
              ? {
                  type: ENUM_FEEDBACK_TYPE.ERROR,
                  message: step1Error,
                }
              : undefined
          }
        />
      )
    } else if (recoveryStep === 2) {
      return (
        <Step2
          theme={theme}
          onSubmit={submitNewPassword}
          onResend={resendEmail}
          error={
            step2Error
              ? {
                  type: ENUM_FEEDBACK_TYPE.ERROR,
                  message: step2Error,
                }
              : undefined
          }
        />
      )
    } else if (recoveryStep === 3) {
      return <Step3 theme={theme} onClick={gotoLogin} />
    }
  }

  return (
    <div className={styles.viewContainer}>
      {shouldRenderLoader()}
      <div className={styles.preferencesContainer}>
        <SysprefWidget />
      </div>

      <div className={styles.logoContainer}>{getLogo()}</div>
      <div className={styles.stepsContainer}>{getStepContent()}</div>

      <div className={classnames([styles.footerContainer, styles[`${theme}`]])}>
        Optimal - Copyright ©2020 Produced by Optimal
      </div>
    </div>
  )
}

export default Recovery
