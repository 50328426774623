import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export enum ENUM_FEEDBACK_TYPE {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export type FeedbackObjType = {
  type: ENUM_FEEDBACK_TYPE
  message?: string
}

interface FormFeedback {
  theme?: string
  feedback: FeedbackObjType
}

export const FormFeedback = (props: FormFeedback) => {
  const feedbackType = props.feedback.type || ENUM_FEEDBACK_TYPE.ERROR

  return (
    <div
      className={classnames([
        styles.feedbackContainer,
        styles[`${props.theme}`],
        styles[`${feedbackType}`],
      ])}
    >
      {props.feedback.message ? props.feedback.message : null}
    </div>
  )
}
