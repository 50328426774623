import { useState } from 'react'
import axios, { AxiosError } from 'axios'

import { API_SERVER_URL, API_VERSION, API_RESOURCE_USERS } from '../configs'

const API_ENDPOINT = 'forgotPassword'
const API_URL = API_SERVER_URL + API_VERSION + API_RESOURCE_USERS + API_ENDPOINT

export const useForgotPassword = () => {
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<boolean | null>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const forgotPassword = async (email: string) => {
    setLoading(true)

    try {
      const response = await axios.post(API_URL, {
        email: email,
      })
      setSuccess(true)
      //
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.response ? err.response.data.message : err.message)
      } else {
        setError('An unexpected error ocurred')
      }
      setSuccess(false)
    }

    setLoading(false)
  }

  return { loading, error, success, forgotPassword }
}
