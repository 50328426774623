import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useHandshakeToken } from '../../API/user/handshakeToken'
import { useAppStore } from '../../app.store'

interface ProtectedRouteProps {
  children: React.ReactNode
  fallbackRoute: string
  requiredRoles?: string[]
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const navigate = useNavigate()
  const { loading, error, success, role, handshake } = useHandshakeToken()

  useEffect(() => {
    handshake()
  }, [])

  useEffect(() => {
    if (success && props.requiredRoles && !props.requiredRoles.includes(role)) {
      console.log('Logged but Unauthorized - Redirecting')
      navigate(props.fallbackRoute)
    } else if (success) {
      console.log('Logged - Rendering target route')
    } else if (error) {
      console.log('Not logged - Redirecting')
      navigate(props.fallbackRoute)
    }
  }, [success, error, role, props.requiredRoles, props.fallbackRoute])

  if (loading || (!success && !error)) {
    return <div>Loading...</div>
  }

  if (success && (!props.requiredRoles || props.requiredRoles.includes(role))) {
    return <>{props.children}</>
  }

  return null
}

export default ProtectedRoute
