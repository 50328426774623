import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useAppStore, ThemeType } from '../../app.store'

import styles from './styles.module.css'

interface DashboardProps {}

const Dashboard = (props: DashboardProps) => {
  const { theme } = useAppStore()

  return (
    <div className={classnames([styles.viewContainer, styles[`${theme}`]])}>
      Dashboard
    </div>
  )
}

export default Dashboard
