import React, { useState } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

interface CheckboxPropsType {
    theme?: string,
    isChecked: boolean,
    onChange?: (boolean) => void,
    label?: string
}

export const Checkbox = (props: CheckboxPropsType) => {
    
    const onChange = (isChecked: boolean): void  => {
        props.onChange ? props.onChange(isChecked) : null
    }

    return (
        <div className={ classnames([styles.checkboxContainer, styles[`${props.theme}`]])}>
            <div className={ classnames([styles.inputContainer])}>
                <input
                    className={ classnames([styles.input, styles[`${props.theme}`]])}
                    type={ 'checkbox' }
                    checked={ props.isChecked }
                    onChange={ (e) => onChange(e.target.checked) }
                />
            </div>
            { props.label && (
                <div className= {classnames([ styles.labelContainer, styles[`${props.theme}`]])}>    
                    { props.label }
                </div>
            )}
        </div>
    )
}