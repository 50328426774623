import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export type TabItemType = {
    key: string,
    label: string
}

export type ItemsType = Array<TabItemType>

interface TabPropsType {
    items: ItemsType,
    defaultActiveKey?: string,
    theme?: string,
    onChange?: (string) => void
}


export const Tabs = (props: TabPropsType) => {
    const [activeKey, setActiveKey] = useState<string | null>(null)

    useEffect(() => {
        props.defaultActiveKey ? setActiveKey(props.defaultActiveKey) : props.items[0]
    },[])

    const onClick = (key: string): void => {
        setActiveKey(key)
        props.onChange ? props.onChange(key) : null
    }
    
    const renderTabs = () => {
        const tabsList = props.items.map((item) => {
            let tabStatus = activeKey === item.key ? 'active' : null

            return (
                <div 
                    key={item.key} 
                    className={ classnames(styles.tab, styles[`${tabStatus}`], styles[`${props.theme}`]) } 
                    onClick={ () => onClick(item.key) }
                >
                    {item.label}
                    <div className={ classnames(styles.decorator)} />
                </div>
            )
        })

        return tabsList
    }

    return (
        <div className={ styles.tabsContainer }>
            <div className={ classnames([styles.tabsListContainer, styles[`${props.theme}`]]) }>
                { renderTabs() }
            </div>
        </div>
    )
}
