import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useAppStore, ThemeType } from '../../app.store'

import styles from './styles.module.css'

import IconLanguage from './../../../public/assets/icons/global.svg'
import IconThemeLight from './../../../public/assets/icons/sun.svg'
import IconThemeDark from './../../../public/assets/icons/moon.svg'

interface SysprefWidgetPropTypes {}

export const SysprefWidget = (props: SysprefWidgetPropTypes) => {
    const { t, i18n } = useTranslation()
    const { theme, toggleTheme } = useAppStore()

    const getThemeIcon = () => {
        let ThemeIcon =  IconThemeLight
        if (theme == ThemeType.Dark) ThemeIcon = IconThemeDark

        return (
            <ThemeIcon className={ classnames([styles.svgIcon, styles[`${theme}`]]) } onClick={ () => { toggleTheme(theme) }}/>
        )
    }

    const toggleLanguage = () => {
        i18n.language === 'en' ? i18n.changeLanguage('pt-BR') : i18n.changeLanguage('en')
      }
    
    
    return (
        <div className={ classnames([styles.sysprefWidgetContainer])}>
            <div className={ classnames([styles.themeContainer])}>
                { getThemeIcon() }
            </div>
            <div className={ classnames([styles.languageContainer, styles[`${theme}`]])} onClick={ toggleLanguage }>
                <IconLanguage className={ classnames([styles.svgIcon, styles[`${theme}`]]) }/>
                { i18n.language }
            </div>
        </div>
    )
}