import { create, StateCreator } from 'zustand'
import { persist } from 'zustand/middleware'

// ------------------------------------------------------------
// Type Definitions
// ------------------------------------------------------------
export enum ThemeType {
  Light = 'light',
  Dark = 'dark',
}

interface AppState {
  theme: ThemeType
  token: string | null
}

interface AppActions {
  setTheme: (themeValue: ThemeType) => void
  toggleTheme: () => void
  setToken: (token: string) => void
}

type AppStoreType = AppState & AppActions
// ------------------------------------------------------------

// ------------------------------------------------------------
// Default Values
// ------------------------------------------------------------
const DEFAULT_THEME: ThemeType = ThemeType.Light
const TOKEN_EXPIRE_DURATION = 1000 * 30

const Store: StateCreator<AppStoreType> = (set) => ({
  theme: ThemeType.Light,
  token: null,
  setTheme: (themeValue: ThemeType) => set({ theme: themeValue }),
  toggleTheme: () =>
    set((state) => ({
      theme: state.theme === ThemeType.Light ? ThemeType.Dark : ThemeType.Light,
    })),
  setToken: (token: string) => set({ token: token }),
})

export const useAppStore = create<AppStoreType>()(
  persist(Store, {
    name: 'opt-appStore', // unique name for your storage key
    getStorage: () => localStorage, // defining the storage type
    onRehydrateStorage: () => (state) => {
      // if (state && state.tokenExpire && state.tokenExpire) {
      //   console.log('token expired, removing it')
      //   const newState = {
      //     ...state,
      //     token: 'bananas',
      //     tokenExpire: Date.now() + TOKEN_EXPIRE_DURATION,
      //   }
      //   console.log('new state')
      //   console.log(newState)
      //   return newState
      // }
      // console.log('-------------------')
      // console.log(state)
      return state
    },
  })
)

// export const useAppStore = create<Store>(
//   persist(
//     (set) => ({
//       theme: ThemeType.Light,
//       token: null,
//       setTheme: (themeValue: ThemeType) => set({ theme: themeValue }),
//       setToken: (token: string) => set({ token }),
//       toggleTheme: () =>
//         set((state: AppState) => ({
//           theme:
//             state.theme === ThemeType.Light ? ThemeType.Dark : ThemeType.Light,
//         })),
//     }),
//     {
//       name: 'app-store',
//       getStorage: () => localStorage,
//       onRehydrateStorage: () => (state) => {
//         // const
//       },
//     }
//   )
// )
