import React from 'react'
import classnames from 'classnames'

import { FormFeedback, ENUM_FEEDBACK_TYPE } from '../FormFeedback'
import type { FeedbackObjType } from '../FormFeedback'

import styles from './styles.module.css'
import { StatesType } from '../Button'

export enum InputType {
  PASSWORD = 'password',
  TEXT = 'text',
}

interface InputTextPropsType {
  defaultValue?: string
  onChange?: (val: string) => void
  onPressEnter?: () => void
  placeholder?: string
  icon?: React.ComponentType<{ className?: string }>
  theme?: string
  isRequired?: boolean
  type?: InputType
  tabIndex?: number
  validation?: FeedbackObjType
}

export const InputText = (props: InputTextPropsType) => {
  const getIcon = (): React.ComponentType | void => {
    if (props.icon) {
      return props.icon
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange ? props.onChange(event.target.value) : null
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.key == 'Enter' && props.onPressEnter ? props.onPressEnter() : null
  }

  const Icon = props.icon
  const isRequired = props.isRequired ? 'required' : null
  const type = props.type ? props.type : InputType.TEXT
  const validationType = props.validation ? props.validation.type : null

  return (
    <div
      className={classnames([
        styles.inputTextContainer,
        styles[`${props.theme}`],
      ])}
    >
      <div
        className={classnames([
          styles.fieldContainer,
          styles[`${props.theme}`],
          styles[`${isRequired}`],
          styles[`${validationType}`],
        ])}
      >
        {Icon && (
          <div
            className={classnames([
              styles.iconContainer,
              styles[`${props.theme}`],
            ])}
          >
            <Icon
              className={classnames([
                styles.iconFill,
                styles[`${validationType}`],
              ])}
            />
          </div>
        )}

        <input
          type={type}
          value={props.defaultValue}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
          className={classnames([
            styles.input,
            styles[`${props.theme}`],
            styles[`${validationType}`],
          ])}
          tabIndex={props.tabIndex ? props.tabIndex : undefined}
        />
      </div>

      {props.validation && props.validation.message && (
        <FormFeedback
          theme={props.theme}
          feedback={{
            type: props.validation.type,
            message: props.validation.message,
          }}
        />
      )}
    </div>
  )
}
