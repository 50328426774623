import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { useLogin } from '../../../../API/user/login'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../../components/Button'
import { InputText, InputType } from '../../../../components/InputText'
import { Checkbox } from '../../../../components/Checkbox'

import {
  FormFeedback,
  ENUM_FEEDBACK_TYPE,
} from '../../../../components/FormFeedback'
import type { FeedbackObjType } from '../../../../components/FormFeedback'

import styles from './styles.module.css'

import IconUser from './../../../../../public/assets/icons/user.svg'
import IconLock from './../../../../../public/assets/icons/lock.svg'

interface LoginFormProps {
  theme: string
  toggleLoader: (val: boolean) => void
}

const LoginForm = (props: LoginFormProps) => {
  const { loading, error: apiError, success: loginSuccess, login } = useLogin()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [email, setEmail] = useState<string | undefined>()
  const [password, setPassword] = useState<string | undefined>()
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [error, setError] = useState<FeedbackObjType | undefined>()

  // If login succeed, it will forward the user to the dashboard route
  useEffect(() => {
    if (loginSuccess === true) {
      navigate('/dashboard')
    }
  }, [loginSuccess])

  // Handle login errors from the login API
  useEffect(() => {
    if (apiError) {
      console.log('effect apiError')
      setError({
        type: ENUM_FEEDBACK_TYPE.ERROR,
        message: apiError,
      })
    }
  }, [apiError])

  // Activate or Deactivate loader based on API responses
  useEffect(() => {
    props.toggleLoader(loading)
  }, [loading])

  // Calls the API login method
  const onSubmitLogin = () => {
    if (email && password) {
      login(email, password, rememberMe)
    } else {
      setError({
        type: ENUM_FEEDBACK_TYPE.ERROR,
        message: 'empyEmailOrPassword',
      })
    }
  }

  // Forward the user to the password recovery route
  const onForgotPassword = () => {
    navigate('/recovery')
  }

  return (
    <div className={classnames(styles.loginContainer)}>
      <div className={classnames(styles.inputsContainer)}>
        <InputText
          onChange={(val) => setEmail(val)}
          icon={IconUser}
          theme={props.theme}
          placeholder={t('homeView.loginTab.email')}
          isRequired={true}
          tabIndex={1}
          validation={error ? { type: ENUM_FEEDBACK_TYPE.ERROR } : undefined}
        />
        <InputText
          onChange={(val) => setPassword(val)}
          onPressEnter={onSubmitLogin}
          icon={IconLock}
          theme={props.theme}
          placeholder={t('homeView.loginTab.password')}
          isRequired={true}
          type={InputType.PASSWORD}
          tabIndex={2}
          validation={error ? { type: ENUM_FEEDBACK_TYPE.ERROR } : undefined}
        />
      </div>
      {error && (
        <div className={classnames(styles.errorContainer)}>
          <FormFeedback
            feedback={{
              type: ENUM_FEEDBACK_TYPE.ERROR,
              message: error.message,
            }}
            theme={props.theme}
          />
        </div>
      )}

      <div className={classnames(styles.loginOptionsContainer)}>
        <Checkbox
          theme={props.theme}
          isChecked={rememberMe}
          label={t('homeView.loginTab.rememberMe')}
          onChange={(val) => setRememberMe(val)}
        />
        <div
          className={classnames(styles.forgotPasswordContainer)}
          onClick={onForgotPassword}
        >
          {t('homeView.loginTab.forgotPassword')}
        </div>
      </div>
      <div className={classnames(styles.actionsContainer)}>
        <Button
          label={t('homeView.loginTab.btnLogin')}
          onClick={onSubmitLogin}
          theme={props.theme}
        />
      </div>
    </div>
  )
}

export default LoginForm
