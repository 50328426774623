import { useState } from 'react'
import axios from 'axios'

import { useAppStore } from '../../app.store'
import { API_SERVER_URL, API_VERSION, API_RESOURCE_USERS } from '../configs'

axios.defaults.withCredentials = true

const API_ENDPOINT = 'handshakeToken'
const API_URL = API_SERVER_URL + API_VERSION + API_RESOURCE_USERS + API_ENDPOINT

export const useHandshakeToken = () => {
  const { token, setToken } = useAppStore()

  const [loading, setLoading] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string | null>()
  const [role, setRole] = useState<string>('unknown')

  const handshake = async () => {
    console.log(`Handshake rendered, token: ${token}`)

    try {
      const response = await axios.post(
        API_URL,
        {},
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      console.log('success')
      setSuccess(true)
      setRole(response.data.data.role)
      //
    } catch (err: unknown) {
      console.log('error')
      console.log(err)
      if (axios.isAxiosError(err)) {
        setError(err.response ? err.response.data.message : err.message)
      } else {
        setError('An unexpected error ocurred')
      }
    }

    setLoading(false)
  }

  return { loading, error, success, role, handshake }
}
