import { useState } from 'react'
import axios, { AxiosError } from 'axios'

import { useAppStore } from '../../app.store'
import { API_SERVER_URL, API_VERSION, API_RESOURCE_USERS } from '../configs'

axios.defaults.withCredentials = true

const API_ENDPOINT = 'login'
const API_URL = API_SERVER_URL + API_VERSION + API_RESOURCE_USERS + API_ENDPOINT

export const useLogin = () => {
  const { setToken } = useAppStore()

  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<boolean | null>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.post(API_URL, {
        email: email,
        password: password,
        rememberMe: rememberMe,
      })
      const { token } = response.data
      setSuccess(true)
      setError(null)
      setToken(token)
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.response ? err.response.data.message : err.message)
      } else {
        setError('An unexpected error ocurred')
      }
      setSuccess(false)
    }

    setLoading(false)
  }

  return { loading, error, success, login }
}
